import { Inject, Injectable } from '@angular/core'
import { HubtypeAiIntentFilter } from 'models/hubtype-ai-intents'
import {
  HutypeAiPhraseCreate,
  HutypeAiPhraseFilter,
} from 'models/hubtype-ai-phrase'
import { HubtypeAiProject } from 'models/hubtype-ai-project'
import { Paginator } from 'models/paginator'
import { Observable } from 'rxjs'
import { HubtypeApiService } from './hubtype-api.service'

@Injectable({
  providedIn: 'root',
})
export class AiService {
  constructor(@Inject('apiService') private apiService: HubtypeApiService) {}

  getTrainingJobs(projectId: string): Observable<any> {
    return this.apiService.get(`/babel/projects/${projectId}/training_jobs/`)
  }

  loadTrainingJob(projectId: string): Observable<any[]> {
    return this.apiService.post(`/babel/projects/${projectId}/train/`)
  }

  getProjects(): Observable<Paginator<HubtypeAiProject>> {
    return this.apiService.get(`/babel/projects/?order=-created_at`)
  }

  createProject(projectName: string): Observable<HubtypeAiProject> {
    return this.apiService.post(
      `/babel/projects/`,
      this.getDefaultNewProject(projectName)
    )
  }
  //#region Intents
  getIntent(projectId: string, intentId: string): Observable<any> {
    return this.apiService.get(
      `/babel/projects/${projectId}/intents/${intentId}`
    )
  }

  testEndpoint(projectId: string, text: string): Observable<any> {
    return this.apiService.post(`/babel/projects/${projectId}/inference/`, {
      text,
    })
  }

  getProject(projectId: string): Observable<any> {
    return this.apiService.get(`/babel/projects/${projectId}/`)
  }

  updateProjectName(
    projectId: string,
    newProjectName: string
  ): Observable<any> {
    return this.apiService.patch(`/babel/projects/${projectId}/`, {
      name: newProjectName,
    })
  }

  deleteProject(projectId: string): Observable<any> {
    return this.apiService.delete(`/babel/projects/${projectId}/`)
  }

  getIntents(
    projectId: string,
    params: HubtypeAiIntentFilter
  ): Observable<Paginator<any>> {
    return this.apiService.get(
      `/babel/projects/${projectId}/intents/?order=-created_at`,
      params
    )
  }

  createIntent(projectId: string, intentName: string): Observable<any> {
    return this.apiService.post(`/babel/projects/${projectId}/intents/`, {
      name: intentName,
    })
  }

  deleteIntent(projectId: string, intentId: string): Observable<any> {
    return this.apiService.delete(
      `/babel/projects/${projectId}/intents/${intentId}/`
    )
  }
  //#endregion

  //#region Phrases

  getPhrases(
    projectId: string,
    intentId: string,
    params?: HutypeAiPhraseFilter
  ): Observable<Paginator<any>> {
    return this.apiService.get(
      `/babel/projects/${projectId}/samples/?intent_id=${intentId}&order=-created_at`,
      params
    )
  }

  createPhrase(
    projectId: string,
    phrase: HutypeAiPhraseCreate
  ): Observable<any> {
    return this.apiService.post(
      `/babel/projects/${projectId}/samples/`,
      phrase,
      {},
      'v1',
      false
    )
  }

  updatePhrase(
    projectId: string,
    phrase: HutypeAiPhraseCreate
  ): Observable<any> {
    return this.apiService.patch(
      `/babel/projects/${projectId}/samples/${phrase.intent}/`,
      {
        text: phrase.text,
        language: phrase.language,
      },
      'v1',
      false
    )
  }

  deletePhrase(projectId: string, intentId: string): Observable<any> {
    return this.apiService.delete(
      `/babel/projects/${projectId}/samples/${intentId}/`
    )
  }

  //#endregion

  private getDefaultNewProject(name) {
    return {
      name,
      /* eslint-disable @typescript-eslint/naming-convention */
      training_instance_count: 1,
      training_instance_type: 'ml.g4dn.xlarge',
      endpoint_instance_count: 1,
      endpoint_instance_type: 'ml.t2.medium',
      hyperparameters: {
        epochs: 8,
        max_length: 128,
        model_name: 'bert-base-uncased',
        learning_rate: 2e-5,
        tokenizer_name: 'bert-base-uncased',
        eval_batch_size: 8,
        train_batch_size: 8,
      },
      /* eslint-disable @typescript-eslint/naming-convention */
    }
  }
}
